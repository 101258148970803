/** @jsx jsx */
import { BigNumber, utils } from "ethers";
import { Fragment, memo, ReactElement, useCallback, useState } from "react";
import { Box, BoxProps, Button, jsx, Text } from "theme-ui";
import { delayedFadeInSx } from "../styles";
import { USER_REJECTED } from "../util/constants";
import { PharaohClient } from "../util/pharaoh";

export interface RewardsViewProps extends BoxProps {
  pharaoh: PharaohClient;
  rewards: BigNumber | undefined;
}

enum ClaimStatus {
  NOT_CLAIMED,
  AWAITING_CONFIRM,
  CLAIMING,
  USER_REJECTED,
}

const RewardsView = memo(function RewardsView({
  pharaoh,
  rewards,
  ...boxProps
}: RewardsViewProps): ReactElement {
  const [status, setStatus] = useState(ClaimStatus.NOT_CLAIMED);
  const [rewardsClaimed, setRewardsClaimed] = useState(false);

  const handleClaim = useCallback(async () => {
    setStatus(ClaimStatus.AWAITING_CONFIRM);
    try {
      const transaction = await pharaoh.claimFunds();
      setStatus(ClaimStatus.CLAIMING);
      await transaction.wait();
      // Kludge. We don't get an event when rewards are claimed, so just force
      // the displayed number to 0.
      setRewardsClaimed(true);
      setStatus(ClaimStatus.NOT_CLAIMED);
    } catch (error) {
      setStatus(
        error.code === USER_REJECTED
          ? ClaimStatus.USER_REJECTED
          : ClaimStatus.NOT_CLAIMED
      );
    }
  }, [pharaoh]);

  function getButtonText(): string {
    switch (status) {
      case ClaimStatus.NOT_CLAIMED:
      case ClaimStatus.USER_REJECTED:
        return "Claim rewards";
      case ClaimStatus.AWAITING_CONFIRM:
        return "Claiming…";
      case ClaimStatus.CLAIMING:
        return "Sending reward…";
    }
  }

  function renderButton(): ReactElement {
    const buttonDisabled =
      status === ClaimStatus.AWAITING_CONFIRM ||
      status === ClaimStatus.CLAIMING;
    return (
      <Button disabled={buttonDisabled} onClick={handleClaim}>
        {getButtonText()}
      </Button>
    );
  }

  function renderContents(): ReactElement {
    if (rewards == null) {
      return (
        <Text key="loading-tokens" sx={{ color: "muted", ...delayedFadeInSx }}>
          Loading rewards…
        </Text>
      );
    }
    return (
      <Fragment>
        <Box>
          <Text sx={{ color: "muted" }}>Your rewards:</Text>{" "}
          {rewardsClaimed ? 0 : utils.formatEther(rewards)} ETH
        </Box>
        <Box mt={3}>
          {rewards.isZero() ? (
            <Text>Recruit more followers to earn rewards!</Text>
          ) : (
            renderButton()
          )}
        </Box>
      </Fragment>
    );
  }

  return <Box {...boxProps}>{renderContents()}</Box>;
});
export default RewardsView;
