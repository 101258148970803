import {
  BigNumber,
  Contract,
  ContractTransaction,
  providers,
  Signer,
} from "ethers";
import { useRef } from "react";
import { chainFrom, range } from "transducist";
import pharaohSpec from "../sc-generated/artifacts/contracts/Pharaoh.sol/Pharaoh.json";
import { Pharaoh } from "../sc-generated/typechain/Pharaoh";
import { CancelFunction } from "../types";
import { getPharaohAddress, PRICE } from "./constants";
import { EthNetwork, useMetamask } from "./metamask";

export function usePharaoh(): PharaohClient | undefined {
  const pharaoh = useRef<PharaohClient>();
  const { network, currentAccount, connectedProvider } = useMetamask();
  if (network && currentAccount && connectedProvider && !pharaoh.current) {
    const signer = new providers.Web3Provider(connectedProvider).getSigner();
    pharaoh.current = new PharaohClient(signer, currentAccount, network);
  }
  return pharaoh.current;
}

export interface PharaohUpdate {
  rewards: BigNumber;
  ownedTokens: number[];
}

export class PharaohClient {
  private readonly pharaoh: Pharaoh;

  constructor(
    signer: Signer,
    private readonly owner: string,
    network: EthNetwork
  ) {
    this.pharaoh = (new Contract(
      getPharaohAddress(network),
      pharaohSpec.abi,
      signer
    ) as unknown) as Pharaoh;
  }

  public async getOwnedTokens(): Promise<number[]> {
    const tokenCount = await this.pharaoh.balanceOf(this.owner);
    return Promise.all(
      chainFrom(range(+tokenCount))
        .map((i) =>
          this.pharaoh.tokenOfOwnerByIndex(this.owner, i).then((n) => +n)
        )
        .toArray()
    );
  }

  public async getClaimableFunds(): Promise<BigNumber> {
    return this.pharaoh.payments(this.owner);
  }

  public claimFunds(): Promise<ContractTransaction> {
    return this.pharaoh.withdrawPayments(this.owner);
  }

  public mint(parentTokenId: number): Promise<ContractTransaction> {
    return this.pharaoh.mint(parentTokenId, { value: PRICE });
  }

  public watch(handler: (update: PharaohUpdate) => void): CancelFunction {
    const listener = async () => {
      const [rewards, ownedTokens] = await Promise.all([
        this.getClaimableFunds(),
        this.getOwnedTokens(),
      ]);
      handler({ rewards, ownedTokens });
    };
    const filter = this.pharaoh.filters.PharaohMinted(null, null, null);
    this.pharaoh.on(filter, listener);
    return () => this.pharaoh.off(filter, listener);
  }
}
