/** @jsx jsx */
import { BigNumber } from "@ethersproject/bignumber";
import { memo, ReactElement, useEffect, useState } from "react";
import { jsx } from "theme-ui";
import { PharaohClient } from "../util/pharaoh";
import Container from "./Container";
import InviteText from "./InviteText";
import PurchaseButton from "./PurchaseButton";
import RewardsView from "./RewardsView";
import TokensView from "./TokensView";

export interface MainViewProps {
  pharaoh: PharaohClient;
  inviteTokenId: number | undefined;
}

const MainView = memo(function MainView({
  pharaoh,
  inviteTokenId,
}: MainViewProps): ReactElement {
  const [rewards, setRewards] = useState<BigNumber>();
  const [ownedTokens, setOwnedTokens] = useState<number[]>();

  useEffect(() => {
    if (pharaoh) {
      pharaoh.getClaimableFunds().then(setRewards);
      pharaoh.getOwnedTokens().then(setOwnedTokens);
      pharaoh.watch(({ rewards, ownedTokens }) => {
        setRewards(rewards);
        setOwnedTokens(ownedTokens);
      });
    }
  }, [pharaoh]);

  return (
    <Container small={true} sx={{ display: "flex", flexDirection: "column" }}>
      {inviteTokenId != null && (
        <InviteText mb={4} inviteTokenId={inviteTokenId} />
      )}
      <PurchaseButton pharaoh={pharaoh} parentTokenId={inviteTokenId || 1} />
      {ownedTokens && ownedTokens.length > 0 && (
        <RewardsView mt={4} pharaoh={pharaoh} rewards={rewards} />
      )}
      <TokensView mt={4} tokenIds={ownedTokens} />
    </Container>
  );
});
export default MainView;
