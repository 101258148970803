/** @jsx jsx */
import {
  Fragment,
  memo,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, BoxProps, Flex, jsx, Text } from "theme-ui";
import { delayedFadeInSx } from "../styles";

export interface TokensViewProps extends BoxProps {
  tokenIds: number[] | undefined;
}

const TokensView = memo(function TokensView({
  tokenIds,
  ...boxProps
}: TokensViewProps): ReactElement {
  function renderContents(): ReactElement {
    if (!tokenIds) {
      return (
        <Text key="loading-tokens" sx={{ color: "muted", ...delayedFadeInSx }}>
          Loading tokens…
        </Text>
      );
    }
    if (tokenIds.length === 0) {
      return (
        <Text sx={{ textAlign: "center", color: "muted" }}>
          You don't have any tokens yet.
        </Text>
      );
    }
    return (
      <Fragment>
        <Text sx={{ color: "muted" }}>Your tokens:</Text>
        {tokenIds.map((tokenId) => (
          <TokenView key={tokenId} tokenId={tokenId} />
        ))}
      </Fragment>
    );
  }

  return <Box {...boxProps}>{renderContents()}</Box>;
});
export default TokensView;

interface TokenViewProps {
  tokenId: number;
}

const TokenView = memo(function TokenView({
  tokenId,
}: TokenViewProps): ReactElement {
  const [justCopied, setJustCopied] = useState(false);
  const hiddenTextRef = useRef<HTMLTextAreaElement | null>(null);
  const inviteLink = useMemo(() => getInviteLink(tokenId), [tokenId]);

  const copyInviteLink = useCallback(() => {
    if (!hiddenTextRef.current) {
      return;
    }
    hiddenTextRef.current.select();
    document.execCommand("copy");
    setJustCopied(true);
  }, [tokenId]);

  const handleMouseLeave = useCallback(
    // Wait a moment before changing text to give transition a chance to fade away.
    () => setTimeout(() => setJustCopied(false), 100),
    []
  );

  return (
    <Flex
      p={3}
      mt={2}
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "darkGray.4",
        borderRadius: "3px",
        cursor: "pointer",
        userSelect: "none",
        boxShadow:
          "0 0 0 1px rgb(16 22 26 / 20%), 0 1px 1px rgb(16 22 26 / 40%), 0 2px 6px rgb(16 22 26 / 40%)",
        transition:
          "box-shadow .2s cubic-bezier(.4,1,.75,.9),-webkit-transform .2s cubic-bezier(.4,1,.75,.9),-webkit-box-shadow .2s cubic-bezier(.4,1,.75,.9)",
        ".invite-link": { opacity: 0, transition: "opacity .1s ease-in-out" },
        ":hover": {
          ".invite-link": { opacity: 1 },
          boxShadow:
            "0 0 0 1px rgb(16 22 26 / 20%), 0 2px 4px rgb(16 22 26 / 40%), 0 8px 24px rgb(16 22 26 / 40%)",
          ":active": {
            boxShadow:
              "0 0 0 1px rgb(16 22 26 / 20%), 0 4px 8px rgb(16 22 26 / 40%), 0 18px 46px 6px rgb(16 22 26 / 40%)",
          },
        },
      }}
      onClick={copyInviteLink}
      onMouseLeave={handleMouseLeave}
    >
      <Text>Token #{tokenId}</Text>
      <Text
        sx={{ fontSize: 0, color: justCopied ? "green.5" : "disabled" }}
        className="invite-link"
      >
        {justCopied ? "Copied!" : "Copy invite link"}
      </Text>
      <textarea
        ref={hiddenTextRef}
        sx={{
          opacity: 0,
          height: 0,
          width: 0,
          position: "absolute",
        }}
        value={inviteLink}
        readOnly={true}
        tabIndex={-1}
      />
    </Flex>
  );
});

function getInviteLink(tokenId: number): string {
  const a = document.createElement("a");
  a.href = `/?invite-token-id=${tokenId}`;
  return a.href;
}
