/** @jsx jsx */
import { providers } from "ethers";
import { memo, ReactElement, useRef } from "react";
import { Box, Button, jsx, Text } from "theme-ui";
import { IS_PROD } from "../util/constants";
import { EthNetwork, useMetamask } from "../util/metamask";
import { PharaohClient } from "../util/pharaoh";

const VALID_NETWORKS = new Set([
  EthNetwork.GOERLI,
  EthNetwork.KOVAN,
  EthNetwork.RINKEBY,
]);
if (!IS_PROD) {
  VALID_NETWORKS.add(EthNetwork.HARDHAT);
}

export interface MetamaskLoaderProps {
  children(pharoah: PharaohClient): ReactElement;
}

const MetamaskLoader = memo(function MetamaskLoader({
  children,
}: MetamaskLoaderProps): ReactElement | null {
  const {
    isInstalled,
    isInitialized,
    isConnecting,
    userRejected,
    network,
    currentAccount,
    connectedProvider,
    connect,
  } = useMetamask();
  const pharaoh = useRef<PharaohClient>();

  if (typeof window === "undefined") {
    // Don't render during server-side rendering. We don't want a flash of
    // text from SSR.
    return null;
  }
  if (!isInstalled) {
    return <Text>Metamask is required.</Text>;
  }
  if (!isInitialized) {
    return null;
  }
  if (network && !VALID_NETWORKS.has(network)) {
    return (
      <Text>
        {IS_PROD
          ? "Please switch to Goerli, Rinkeby, or Kovan."
          : "Please switch to Hardhat (localhost:8545, chain ID 31337), Goerli, Rinkeby, or Kovan."}
      </Text>
    );
  }
  if (isConnecting) {
    return <Button disabled={true}>Connecting…</Button>;
  }
  if (!currentAccount) {
    return (
      <Box>
        <Button onClick={connect}>Connect to Metamask</Button>
        {userRejected && (
          <Text mt="12px" sx={{ fontSize: 2 }} color="red.4">
            User rejected connection. Please try again.
          </Text>
        )}
      </Box>
    );
  }
  if (!network || !connectedProvider) {
    throw new Error("Invalid state.");
  }
  if (!pharaoh.current) {
    const signer = new providers.Web3Provider(connectedProvider).getSigner();
    pharaoh.current = new PharaohClient(signer, currentAccount, network);
  }
  return children(pharaoh.current);
});
export default MetamaskLoader;
