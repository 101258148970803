/** @jsx jsx */
import { memo, ReactElement, useCallback, useState } from "react";
import { BoxProps, Button, Flex, jsx, Text } from "theme-ui";
import { delay } from "../util/async";
import { PRICE_TEXT, USER_REJECTED } from "../util/constants";
import { PharaohClient } from "../util/pharaoh";

export interface PurchaseButtonProps extends BoxProps {
  pharaoh: PharaohClient;
  parentTokenId: number;
}

enum MintStatus {
  NOT_MINTING,
  AWAITING_CONFIRM,
  MINTING,
  USER_REJECTED,
}

const PurchaseButton = memo(function PurchaseButton({
  pharaoh,
  parentTokenId,
  ...boxProps
}: PurchaseButtonProps): ReactElement {
  const [status, setStatus] = useState(MintStatus.NOT_MINTING);

  const handleClick = useCallback(async () => {
    setStatus(MintStatus.AWAITING_CONFIRM);
    try {
      const transaction = await pharaoh.mint(parentTokenId);
      setStatus(MintStatus.MINTING);
      await transaction.wait();
      // Kludge to wait for state to catch up.
      await delay(2000);
      setStatus(MintStatus.NOT_MINTING);
    } catch (error) {
      setStatus(
        error.code === USER_REJECTED
          ? MintStatus.USER_REJECTED
          : MintStatus.NOT_MINTING
      );
    }
  }, [pharaoh, parentTokenId]);

  function getButtonText(): string {
    switch (status) {
      case MintStatus.NOT_MINTING:
      case MintStatus.USER_REJECTED:
        return `Purchase token – ${PRICE_TEXT}`;
      case MintStatus.AWAITING_CONFIRM:
        return "Purchasing…";
      case MintStatus.MINTING:
        return "Minting…";
    }
  }

  const buttonDisabled =
    status === MintStatus.AWAITING_CONFIRM || status === MintStatus.MINTING;

  return (
    <Flex {...boxProps} sx={{ flexDirection: "column", alignItems: "center" }}>
      <Button disabled={buttonDisabled} onClick={handleClick}>
        {getButtonText()}
      </Button>
      {status === MintStatus.USER_REJECTED && (
        <Text mt={2} sx={{ fontSize: 1 }} color="red.4">
          User cancelled transaction.
        </Text>
      )}
    </Flex>
  );
});
export default PurchaseButton;
