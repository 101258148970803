/** @jsx jsx */
import { Link } from "gatsby";
import { memo, ReactElement } from "react";
import { Flex, jsx } from "theme-ui";
import AccountText from "./AccountText";
import Trappings, { TrappingsProps } from "./Trappings";

const MainTrappings = memo(function MainTrappings({
  children,
  ...trappingsProps
}: TrappingsProps): ReactElement {
  return (
    <Trappings {...trappingsProps}>
      <Flex
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </Flex>
      <Flex mt={3} sx={{ position: "relative", justifyContent: "center" }}>
        <AccountText />
      </Flex>
      <Link
        to="/about"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          mt: [4, 4, 3],
          mr: 3,
          fontSize: 1,
        }}
      >
        About this app
      </Link>
    </Trappings>
  );
});
export default MainTrappings;
