/** @jsx jsx */
import { memo, ReactElement } from "react";
import { Box, BoxProps, jsx } from "theme-ui";

export interface InviteTextProps extends BoxProps {
  inviteTokenId: number;
}

const InviteText = memo(function InviteText({
  inviteTokenId,
  ...boxProps
}: InviteTextProps): ReactElement {
  return (
    <Box sx={{}} {...boxProps}>
      <Box sx={{ fontSize: 5, color: "orange.5", textAlign: "center" }}>
        Invitation received!
      </Box>
      <Box mt={3}>
        You have been invited by the owner of <b>Token #{inviteTokenId}</b>.
        Purchase a token to join their empire and begin building your own!
      </Box>
    </Box>
  );
});
export default InviteText;
