/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParam } from "use-query-params";
import MainTrappings from "../components/MainTrappings";
import MainView from "../components/MainView";
import MetamaskLoader from "../components/MetamaskLoader";

const IndexPage = memo(function IndexPage(): ReactElement {
  const [inviteTokenIdParam] = useQueryParam("invite-token-id", StringParam);
  const inviteTokenId = inviteTokenIdParam ? +inviteTokenIdParam : undefined;

  return (
    <MainTrappings title="Pharaoh Token">
      <MetamaskLoader>
        {(pharaoh) => (
          <MainView pharaoh={pharaoh} inviteTokenId={inviteTokenId} />
        )}
      </MetamaskLoader>
    </MainTrappings>
  );
});
export default IndexPage;
